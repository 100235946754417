import { MatDialog } from "@angular/material/dialog";
import { BleClient, BleDevice } from "@capacitor-community/bluetooth-le";
import { BleService } from "@capacitor-community/bluetooth-le/dist/esm/definitions";
import { DateTime } from "luxon";

import { ErrorDialogComponent } from "../../../../business/components/error-dialog/error-dialog.component";
import { ErrorMessage } from "../../../../business/components/error-dialog/error-message";
import { RegisterFormComponent } from "../../../../business/components/register-form/register-form.component";
import { Measurement } from "../../../../business/datamodel/measurement";
import { BackendService } from "../../../../business/services/backend/backend-service";
import { SettingsService } from "../../../../business/services/settings/settings-service";
import { DeviceSubscriptionStatus } from "../../../../business/services/subscription/device.subscription.status";
import { BluetoothStatus } from "../bluetooth-status";
import { BluetoothDevice } from "./bluetooth.device";
import { DeviceCodes } from "./device-codes";
import { DeviceNames } from "./device-names";

/**
 * This class implements creating a connection the device "Detail-O-Meter IQ" via bluetooth low energy and handles reading measurements from it.
 */
export class Glossmeter extends BluetoothDevice {
    public constructor(private backendService: BackendService) {
        super(DeviceNames.unknown);
    }

    public hiddenValues: Array<string> = [];

    private readonly measurementService: string = "49535343-fe7d-4ae5-8fa9-9fafd205e455";
    private measurementCharacteristic: string = "49535343-1E4D-4BD9-BA61-23C647249616";

    private readonly deviceInfoService: string = "ef9b2fb9-dc06-4432-8f46-f292abec4e93";
    private deviceInfoCharacteristic: string = "64767bab-ea07-4f78-9796-3b27cf565029";

    public override requiredNamePrefix?: string = undefined;
    public requiredServices: Array<string> = [this.measurementService];
    public requiredAdvertisingServices: Array<string> = [this.deviceInfoService];
    private device?: BleDevice;

    private mergeWithPreviousLine: boolean = false;
    private lineBuffer: Array<string> = [];

    public async connect(device: BleDevice, dialog: MatDialog, settingsService: SettingsService, onData: (measurement: Measurement) => void, onDisconnect: () => void): Promise<BluetoothStatus> {
        this.device = device;

        await BleClient.connect(this.device.deviceId, () => {
            this.device = undefined;
            onDisconnect();
        });

        if (settingsService.licenseCheckEnabled) {
            const serialNumber: string = await this.readSerialNumber();
            const subscriptionStatus: DeviceSubscriptionStatus = await this.backendService.hasValidLicense(serialNumber);
            if (subscriptionStatus != DeviceSubscriptionStatus.active) {
                onDisconnect();
                await this.disconnect();
                dialog.open(ErrorDialogComponent, {
                    data: {
                        title: "Subscription.missingSubscription",
                        advice: [subscriptionStatus],
                        details: [],
                        severity: "warn",
                        buttons: [{
                            title: "ErrorDialog.requestLicense",
                            component: RegisterFormComponent,
                            payload: serialNumber
                        }]
                    } as ErrorMessage
                });
                return BluetoothStatus.disconnected;
            }
        }

        this.updateDeviceCodeAndName();
        this.updateHiddenValues();

        await BleClient.startNotifications(this.device.deviceId, this.measurementService, this.measurementCharacteristic, (dataView: DataView) => {
            const decoder: TextDecoder = new TextDecoder();
            const data: string = decoder.decode(dataView).replace(/\r/g, "");
            const endsWithSeparator: boolean = data.length > 0 && data[data.length-1] == "\n";
            const startsWithSeparator: boolean = data.length > 0 && data[0][0] == "\n";
            let lines: Array<string> = data.split("\n").map((value?: string): string => value?.trim() ?? "").filter((value: string) => !!value);
            if (lines.length <= 0) {
                return;
            }

            // TODO: Clear buffer if last data measured is more than 3 seconds in the past

            // Detect SOF
            if (!this.lineBuffer.length && lines[0] != "SOF") {
                window.alert("No SOF");
                return;
            }

            for (let lineIndex: number = 0; lineIndex < lines.length; lineIndex++) {
                const rawLine: string = lines[lineIndex];
                const line: string = rawLine.trim();
                if (line) {
                    if (lineIndex == 0 && this.mergeWithPreviousLine && this.lineBuffer.length > 0 && !startsWithSeparator) {
                        this.lineBuffer[this.lineBuffer.length-1] = this.lineBuffer[this.lineBuffer.length-1] + line;
                    } else {
                        this.lineBuffer.push(line);
                    }
                }
            }
            this.mergeWithPreviousLine = !endsWithSeparator;

            if (this.lineBuffer.length <= 0) {
                return;
            }

            // Detect EOF
            if (this.lineBuffer[this.lineBuffer.length-1] != "EOF") {
                return;
            }

            lines = this.lineBuffer.filter((value: string) => value != "SOF" && value != "EOF");
            console.info(lines);
            this.lineBuffer = [];

            // Detect if ID option has been activated
            if (!lines[0].includes("=")) {
                window.alert("ID option not activated");
                return;
            }

            const measurement: Measurement = new Measurement();
            measurement.device = this.deviceName;
            measurement.deviceId = this.device?.deviceId;
            measurement.timestamp = DateTime.now();
            measurement.values = [];
            measurement.chart = [];
            let atLeastOneValidValue: boolean = false;
            for (const line of lines) {
                if (line.includes("=")) {
                    const splits: Array<string> = line.split("=");
                    if (splits.length == 2 && splits[0].trim().length > 0 && splits[1].trim().length > 0) {
                        const valueName: string = splits[0];
                        const value: string = splits[1].trim();
                        if (valueName == "Serial No.") {
                            measurement.serialNumber = value;
                        } else if (valueName.startsWith("D-") || valueName.startsWith("D+")) {
                            const floatValue: number = Number.parseFloat(value);
                            if (!Number.isNaN(floatValue)) {
                                measurement.chart.push(floatValue);
                            }
                            continue;
                        } else if (this.hiddenValues.includes(valueName)) {
                            continue;
                        }
                        atLeastOneValidValue = true;
                        measurement.values.push({
                            name: `Measurement.${valueName}`,
                            value: value
                        });
                    }
                }
            }
            if (atLeastOneValidValue) {
                onData(measurement);
            }
        });
        return BluetoothStatus.connected;
    }

    public async disconnect(): Promise<void> {
        if (this.device) {
            await BleClient.disconnect(this.device.deviceId);
        }
        this.device = undefined;
    }

    public async isConnected(): Promise<boolean> {
        try {
            if (this.device) {
                const services: Array<BleService> = await BleClient.getServices(this.device.deviceId);
                return services.length > 0;
            }
        } catch (error: any) {
            console.error(error);
        }
        return false;
    }

    private async readSerialNumber(): Promise<string> {
        if (this.device) {
            const deviceInfo: DataView = await BleClient.read(this.device.deviceId, this.deviceInfoService, this.deviceInfoCharacteristic);
            const deviceInfoAsString: string = new TextDecoder().decode(deviceInfo);

            const splits: Array<string> = deviceInfoAsString.split("-");
            if (splits.length > 2) {
                return splits[1];
            }
        }
        return "unknown device";
    }

    private updateDeviceCodeAndName(): void {
        if (!this.device?.name) {
            this.deviceName = DeviceNames.iq;
            return;
        }
        const parts: Array<string> = this.device.name.split("-");
        if (parts.length < 2) {
            this.deviceCode = DeviceCodes.iq;
            this.deviceName = DeviceNames.iq;
            return;
        }

        switch (parts[0]) {
            case DeviceCodes.detailometer:
                this.deviceCode = DeviceCodes.detailometer;
                this.deviceName = DeviceNames.detailometer;
                break;
            case DeviceCodes.duo:
                this.deviceCode = DeviceCodes.duo;
                this.deviceName = DeviceNames.duo;
                break;
            case DeviceCodes.novoGloss:
                this.deviceCode = DeviceCodes.novoGloss;
                this.deviceName = DeviceNames.novoGloss;
                break;
            case DeviceCodes.flex:
                this.deviceCode = DeviceCodes.flex;
                this.deviceName = DeviceNames.flex;
                break;
            default:
                this.deviceCode = DeviceCodes.iq;
                this.deviceName = DeviceNames.iq;
                break;
        }
    }

    private updateHiddenValues(): void {
        this.hiddenValues = [
            "Time",
            "Date",
            "Pass/Fail",
            "Calibrated",
            "Serial No.",
            "Certified",
            "Cdiode",
            "ASTM D4039"
        ];

        if (this.deviceCode == DeviceCodes.detailometer) {
            this.hiddenValues.push(...["Haze C", "HAZE C", "GLOSS60", "GLOSS85"]);
        }
    }
}
