import { DateTime } from "luxon";

import { CryptoHelper } from "../../base/helper/crypto-helper";
import { MeasuredValue } from "./measured-value";

/**
 * A Measurement contains all measured values of a single measuring event of a device. A device may produce multiple values in one measurement.
 */
export class Measurement {
    constructor() {
        this.id = CryptoHelper.getUUID();
    }

    public device?: string;
    public deviceId?: string;
    public serialNumber?: string;
    public timestamp?: DateTime;
    public values: Array<MeasuredValue> = [];
    public chart: Array<number> = [];
    public comment?: string;
    public readonly id: string;

    /**
     * Indicates if the value of the measurement is a measured value or meta data like device, deviceId, ...
     * @param measurement - The measurement which should contain the value in its measured values.
     * @param valueName - The name to search in the measured values.
     * @returns True if and only if the name was found in the measured values.
     */
    public static isMeasuredValue(measurement: Measurement, valueName: string): boolean {
        for (const measuredValue of measurement.values) {
            if (measuredValue.name == valueName) {
                return true;
            }
        }
        return false;
    }
}
