import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BleClient, BleDevice } from "@capacitor-community/bluetooth-le";
import { Subject } from "rxjs";

import { ErrorDialogComponent } from "../../../business/components/error-dialog/error-dialog.component";
import { ErrorMessage } from "../../../business/components/error-dialog/error-message";
import { Measurement } from "../../../business/datamodel/measurement";
import { SettingsService } from "../../../business/services/settings/settings-service";
import { BluetoothConnection } from "./bluetooth-connection";
import { BluetoothStatus } from "./bluetooth-status";
import { BluetoothDevice } from "./devices/bluetooth.device";

/**
 * BluetoothService is the service for the application when communication via BLE is necessary.
 */
@Injectable({
    providedIn: "root"
})
export class BluetoothService {
    constructor(
        public dialog: MatDialog,
        private readonly settingsService: SettingsService
    ) {
    }

    public allDevicesStatus: BluetoothStatus = BluetoothStatus.unknown;
    public connected: boolean = false;
    public connections: Array<BluetoothConnection> = [];
    public measurements: Array<Measurement> = [];

    public readonly allDevicesStatusChanged: Subject<BluetoothStatus> = new Subject<BluetoothStatus>();
    public readonly onData: Subject<Measurement> = new Subject<Measurement>();
    public readonly onDeleteMeasurement: Subject<Measurement> = new Subject<Measurement>();

    private isInitialized: boolean = false;
    private isUnavailable: boolean = false;

    public async initialize(): Promise<boolean|Error> {
        if (this.isInitialized) {
            return true;
        }
        try {
            await BleClient.initialize({ androidNeverForLocation: false });

            this.isUnavailable = false;
            const enabled: boolean = BleClient && await BleClient.isEnabled();
            if (enabled) {
                this.isInitialized = true;
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.warn(error);
            this.isUnavailable = true;
            return error as Error;
        } finally {
            this.recalculateAllDevicesStatus();
        }
    }

    public async connect(device: BluetoothDevice): Promise<void> {
        if (!this.isInitialized) {
            await BleClient.initialize({ androidNeverForLocation: false });
        }
        await this.disconnect(device);

        let connectedDevice: BleDevice|undefined = undefined;
        try {
            connectedDevice = await BleClient.requestDevice({
                services: device.requiredAdvertisingServices.length ? device.requiredAdvertisingServices : undefined,
                optionalServices: device.requiredServices.length ? device.requiredServices : undefined,
                namePrefix: device.requiredNamePrefix
            });
        } catch (error: Error|any) {
            // User cancelled connect
            console.warn(error);
            return;
        }

        try {
            this.connections.push(new BluetoothConnection(device, BluetoothStatus.connecting));
            this.updateConnectionStatus(device, BluetoothStatus.connecting);
            const status: BluetoothStatus = await device.connect(connectedDevice, this.dialog, this.settingsService, (measurement: Measurement) => {
                this.measurements.push(measurement);
                this.onData.next(measurement);
            }, () => {
                this.disconnect(device).then();
            });

            this.updateConnectionStatus(device, status);
        } catch (exception: Error|any) {
            await device.disconnect();
            await this.disconnect(device);
            this.dialog.open(ErrorDialogComponent, {
                data: {
                    title: "ErrorDialog.connectionFailure",
                    advice: [],
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    details: [exception.message],
                    severity: "error",
                    buttons: [{ title: "ErrorDialog.buttonAdditionalInformation", uri: "ble-status" }]
                } as ErrorMessage
            });
            this.updateConnectionStatus(device, BluetoothStatus.disconnected);
        }
    }

    public async disconnect(device: BluetoothDevice): Promise<void> {
        try {
            await device.disconnect();
        } catch (error) {
            console.error(`Unable to disconnect ${device.deviceName}.`);
        } finally {
            this.connections = this.connections.filter((connectedDevice: BluetoothConnection) => connectedDevice.device != device);
            this.updateConnectionStatus(device, BluetoothStatus.disconnected);
        }
        this.recalculateAllDevicesStatus();
    }

    public async disconnectAll(): Promise<void> {
        const allDevices: Array<{ device: BluetoothDevice; status: BluetoothStatus }> = [];
        allDevices.push(...this.connections);
        for (const connectedDevice of allDevices) {
            await this.disconnect(connectedDevice.device);
        }
    }

    private updateConnectionStatus(device: BluetoothDevice, newStatus: BluetoothStatus): void {
        const connectedDevice: BluetoothConnection|undefined = this.connections.find((cd: BluetoothConnection) => cd.device == device);
        if (connectedDevice) {
            connectedDevice.status = newStatus;
        }

        this.recalculateAllDevicesStatus();
    }

    private recalculateAllDevicesStatus(): void {
        this.allDevicesStatus = BluetoothStatus.unknown;
        if (this.isUnavailable) {
            this.allDevicesStatus = BluetoothStatus.unavailable;
        } else if (!this.isInitialized) {
            this.allDevicesStatus = BluetoothStatus.disconnected;
        }
        if (this.allDevicesStatus != BluetoothStatus.unknown) {
            this.allDevicesStatusChanged.next(this.allDevicesStatus);
            return;
        }

        for (const connectedDevice of this.connections) {
            if (connectedDevice.status == BluetoothStatus.connecting) {
                this.allDevicesStatus = BluetoothStatus.connecting;
                break;
            }
            if (connectedDevice.status == BluetoothStatus.connected) {
                this.allDevicesStatus = BluetoothStatus.connected;
                break;
            }
        }

        if (this.allDevicesStatus == BluetoothStatus.unknown) {
            this.allDevicesStatus = BluetoothStatus.disconnected;
        }
        this.allDevicesStatusChanged.next(this.allDevicesStatus);
    }

    public deleteMeasurement(measurement: Measurement): void {
        this.measurements = this.measurements.filter((m: Measurement) => m.id != measurement.id);
        this.onDeleteMeasurement.next(measurement);
    }
}
