import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";

import { AppIcons } from "../../../app-icons";
import { BluetoothService } from "../../../base/services/ble/bluetooth.service";
import { BluetoothStatus } from "../../../base/services/ble/bluetooth-status";
import { BluetoothDevice } from "../../../base/services/ble/devices/bluetooth.device";
import { CoatingThicknessGauge } from "../../../base/services/ble/devices/coating-thickness-gauge";
import { DeviceNames } from "../../../base/services/ble/devices/device-names";
import { Glossmeter } from "../../../base/services/ble/devices/glossmeter";
import { NavigationService } from "../../../base/services/navigation/navigation.service";
import { BackendService } from "../../services/backend/backend-service";
import { ErrorDialogComponent } from "../error-dialog/error-dialog.component";
import { ErrorMessage } from "../error-dialog/error-message";

/**
 * DeviceConnectorComponent shows a list of supported bluetooth devices and offers the user the option to select the desired one.
 */
@Component({
    selector: "app-device-connector",
    templateUrl: "./device-connector.component.html",
    styleUrls: ["./device-connector.component.scss"]
})
export class DeviceConnectorComponent implements OnInit, OnDestroy {
    constructor(
        private readonly bluetoothService: BluetoothService,
        private readonly navigationService: NavigationService,
        private backendService: BackendService,
        public dialog: MatDialog
    ) {
    }

    public readonly bluetoothStatusEnum: typeof BluetoothStatus = BluetoothStatus;
    public bluetoothStatus: BluetoothStatus = BluetoothStatus.unknown;
    @Output("deviceSelectedEvent")
    public deviceSelectedEvent: EventEmitter<BluetoothDevice> = new EventEmitter<BluetoothDevice>();
    public supportedDevices: Array<string> = [
        DeviceNames.glossMeter,
        DeviceNames.thicknessGauge
    ];
    protected readonly icons: typeof AppIcons = AppIcons;
    private connectionStatusSubscription?: Subscription;

    public ngOnInit(): void {
        this.connectionStatusSubscription = this.bluetoothService.allDevicesStatusChanged.subscribe((next: BluetoothStatus) => {
            this.bluetoothStatus = next;
        });
        this.bluetoothStatus = this.bluetoothService.allDevicesStatus;
    }

    public ngOnDestroy(): void {
        if (this.connectionStatusSubscription) {
            this.connectionStatusSubscription.unsubscribe();
        }
    }

    public async connect(deviceName: string): Promise<void> {
        let device: BluetoothDevice|undefined;
        switch (deviceName) {
            case DeviceNames.glossMeter: {
                device = new Glossmeter(this.backendService);
                break;
            }
            case DeviceNames.thicknessGauge: {
                device = new CoatingThicknessGauge();
                break;
            }

            default: {
                this.dialog.open(ErrorDialogComponent, {
                    data: {
                        title: "ErrorDialog.deviceNotSupported",
                        advice: [
                            "ErrorDialog.adviceDeviceNotSupported",
                            "ErrorDialog.adviceContactSupport"
                        ],
                        details: [],
                        severity: "error",
                        buttons: [{ title: "ErrorDialog.buttonAdditionalInformation", uri: "ble-status" }]
                    } as ErrorMessage
                });
            }
        }
        if (device) {
            await this.bluetoothService.connect(device);
        }
    }

    public async disconnect(): Promise<void> {
        await this.bluetoothService.disconnectAll();
    }

    public openBleStatusPage(): Promise<boolean> {
        return this.navigationService.navigateForward("ble-status");
    }
}
